import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './component/App';
import { getApplicationDB, assetsLocation, getIndexCssURL } from "./assets/assetsLocation";
import { setGlobalState, useGlobalState } from './utils/state';

// Function to dynamically load CSS
const loadCSS = (url) => {
  return new Promise((resolve, reject) => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    
    link.onload = () => resolve('CSS Loaded Successfully');
    link.onerror = () => reject(new Error('Failed to load CSS'));
    
    document.head.appendChild(link);
  });
};

const InitializeApp = () => {
  // Use global state for ApplicationDB
  const [applicationDB, setApplicationDB] = useGlobalState("ApplicationDB");

  useEffect(() => {
    const initialAppDB = getApplicationDB();
    setApplicationDB(initialAppDB);  // Set global state
    console.log('Set ApplicationDB:', initialAppDB);

    // Generate the URL for the CSS
    const indexCssURL = `${assetsLocation}${initialAppDB}/resources/index.css`;

    // Load the CSS file from the URL
    loadCSS(indexCssURL)
      .then(() => console.log('CSS Loaded Successfully'))
      .catch((error) => console.error('Error loading CSS:', error.message));
  }, [setApplicationDB]); 

  return <App />;
};

// Render the application
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<InitializeApp />);

