import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import HomeComponent from './HomeComponent';
import { assetsLocation, ApplicationDB, setApplicationDB } from '../assets/assetsLocation.js';// Import the setter function


function AnimatedRoutes() {
    const location = useLocation();
    const [isLoaded, setIsLoaded] = React.useState(false); 
    const [appDB, setAppDB] = React.useState(ApplicationDB); // Create state for ApplicationDB

    React.useEffect(() => {
        // Extract the last segment of the URL path and trim any trailing slashes
        const pathSegments = location.pathname.replace(/\/+$/, '').split('/');
        var lastSegment = pathSegments[pathSegments.length - 1];

        if (!lastSegment) {
            lastSegment = "storyprocess3d";
        } 
        console.log ("Last segment: ", lastSegment);

        const checkFileExists = async () => {
            const filePath = `${assetsLocation}${lastSegment}/resources/mainModel.mp4`;
        
            try {
              const response = await fetch(filePath, { method: 'HEAD' });
              
              if (response.ok) {
                console.log('File exists:', filePath);
              } else {
                    lastSegment = "storyprocess3d";
              }
            } catch (error) {
              console.error('File check error:', error);
              lastSegment = "storyprocess3d";
            }
            setApplicationDB(lastSegment);
            setAppDB(lastSegment); // Update local state
            console.log(ApplicationDB, lastSegment);
        }
        // If the last segment is not empty, set it as ApplicationDB
        if (lastSegment) {
            setApplicationDB(lastSegment);
            setAppDB(lastSegment); // Update local state
            console.log(ApplicationDB, lastSegment);
            setIsLoaded(true); 
        } else {
            // If no segment is provided, set the default value
            setApplicationDB("storyprocess3d");
            setAppDB("storyprocess3d"); // Update local state
            setIsLoaded(true); 
        }
    }, [location]);

    if (!isLoaded || !appDB) {
        // If not loaded, return null or a loading spinner
        return null;  // Nothing renders until ApplicationDB is set
    }

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<HomeComponent />} />
                <Route path={`/${appDB}`} element={<HomeComponent />} /> {/* Dynamic route path */}
            </Routes>
        </AnimatePresence>
    );
}

export default AnimatedRoutes;



