import { waitFor } from "@testing-library/react";

let ApplicationDB = "storyprocess3D"; // Default value

export function setApplicationDB(value) {
    ApplicationDB = value;
}

// Getter function to get the ApplicationDB value safely
export function getApplicationDB() {
    if (!ApplicationDB) {
        throw new Error("ApplicationDB is not set yet.");
    }
    return ApplicationDB;
}

export const assetsLocation = "https://dell-edge-test.s3-accelerate.amazonaws.com/BabylonAssets/";
export const BaseAPI = "https://bubble-backend.storyproc.com/";
export const MainMenuIsButtons = false;
export const carouselType = "1";
export const packageApp = false;
export const TVScreenSolutions = false;
export const TRACKING_ID = "G-728XS2G376";
export function getIndexCssURL() {
    if (!ApplicationDB) {
        throw new Error("ApplicationDB is not set yet.");
    }
    else {
        return `${assetsLocation}${ApplicationDB}/resources/index.css`;
    }
}


export  {ApplicationDB}

